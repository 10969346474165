<template>
  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m1 dmr dmr2">
        <div class="mini_fram" style="position: relative">

          <div class="suremanlink">
            <iframe src="https://www.sureladder.com/?id=one" scrolling="no"
                    frameborder="0"></iframe>
          </div>

        </div>
      </div>
      <div class="m2">

        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
              ref="ltimer"
              :leisure-game-info="leisureGameInfo"
              @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange">슈어맨 사다리 1분 </span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">
            <div class="mg">
              <span class="t">홀/짝</span>
              <div class="mg_btn b2 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_ODDEVEN && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_ODDEVEN,leisureGameInfo.leisureGame.id,1, oddevenConfig.odds1, oddevenConfig, '홀')">
                <div class="r rblue">
                  <span class="n">홀</span>
                  <span class="b">{{ oddevenConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b2 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_ODDEVEN && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_ODDEVEN,leisureGameInfo.leisureGame.id,2,  oddevenConfig.odds2, oddevenConfig, '짝')">
                <div class="r rred">
                  <span class="n">짝</span>
                  <span class="b">{{ oddevenConfig.odds2 }}</span>
                </div>
              </div>
            </div>
            <div class="mg">
              <span class="t">좌/우 출발</span>
              <div class="mg_btn b2 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LEFT_RIGHT && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LEFT_RIGHT,leisureGameInfo.leisureGame.id,1, leftrightConfig.odds1, leftrightConfig, '좌출발')">
                <div class="r rblue">
                  <span class="n">좌</span>
                  <span class="b">{{ leftrightConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b2 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LEFT_RIGHT && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LEFT_RIGHT,leisureGameInfo.leisureGame.id,2, leftrightConfig.odds2, leftrightConfig, '우출발')">
                <div class="r rred">
                  <span class="n">우</span>
                  <span class="b">{{ leftrightConfig.odds2 }}</span>
                </div>
              </div>
            </div>
            <div class="mg">
              <span class="t">3줄/4줄</span>
              <div class="mg_btn b2 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LINE_COUNT && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LINE_COUNT,leisureGameInfo.leisureGame.id,1, lineConfig.odds1, lineConfig, '3줄')">
                <div class="r rblue">
                  <span class="n">3줄</span>
                  <span class="b">{{ lineConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b2 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LINE_COUNT && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LINE_COUNT,leisureGameInfo.leisureGame.id,2, lineConfig.odds2, lineConfig, '4줄')">
                <div class="r rred">
                  <span class="n">4줄</span>
                  <span class="b">{{ lineConfig.odds2 }}</span>
                </div>
              </div>
            </div>
            <!--조합-->
            <div class="mg">
              <span class="t">조합</span>
              <div class="mg_btn b4  bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_CROSS && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_CROSS,leisureGameInfo.leisureGame.id,1, crossConfig.odds1, crossConfig, '좌4홀')">
                <div class="r rblue">
                  <span class="n">좌4홀</span>
                  <span class="b">{{ crossConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b4 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_CROSS && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_CROSS,leisureGameInfo.leisureGame.id,2,  crossConfig.odds2, crossConfig, '좌3짝')">
                <div class="r rred">
                  <span class="n">좌3짝</span>
                  <span class="b">{{ crossConfig.odds2 }}</span>
                </div>
              </div>
              <div class="mg_btn b4  bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_CROSS && selectedInfo.selectedWay === 3}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_CROSS,leisureGameInfo.leisureGame.id,3, crossConfig.odds3, crossConfig, '우4짝')">
                <div class="r rblue">
                  <span class="n">우4짝</span>
                  <span class="b">{{ crossConfig.odds3 }}</span>
                </div>
              </div>
              <div class="mg_btn b4 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_CROSS && selectedInfo.selectedWay === 4}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_CROSS,leisureGameInfo.leisureGame.id,4,  crossConfig.odds4, crossConfig, '우3홀')">
                <div class="r rred">
                  <span class="n">우3홀</span>
                  <span class="b">{{ crossConfig.odds4 }}</span>
                </div>
              </div>
            </div>

          </div>

          <!--파워볼 베팅카트-->
          <leisure-bet-cart-comp
              ref="betCart" :kind="kind"
              :selected-info="selectedInfo">
            슈어맨 사다리 1분
          </leisure-bet-cart-comp>


        </div>
      </div>
      <div class="m3">
        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
            ref="latelyBetList"
            :kind="kind"></leisure-lately-bet-list-comp>

      </div>


    </div>
  </div>
</template>

<script>
import LeisureTimerComp from "@/views/afront/leisure/LeisureTimerComp";
import LeisureBetCartComp from "@/views/afront/leisure/LeisureBetCartComp";
import LeisureBetMasker from "@/views/afront/leisure/LeisureBetMasker";
import LeisureLatelyBetListComp from "@/views/afront/leisure/LeisureLatelyBetListComp";
import LeisureGameIframView from "@/views/afront/leisure/LeisureGameIframView";

import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "@/store/mutation-types";
import leisureConst from "@/common/leisureConst";
import {leisureGameMixin} from "@/common/mixin";
import {getLeisureGameAttributeConfig} from "@/network/leisureRequest";

export default {

  name: "SureManSadali1M",
  mixins: [leisureGameMixin],
  components: {
    LeisureGameIframView,
    LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp,
  },
  data() {
    return {
      kind: leisureConst.LEISURE_KIND_SUREMAN_SADALI_1M
    }
  },

  methods: {
    initLeisureGameArttributeConfig() {
      getLeisureGameAttributeConfig().then(res => {
        if (res.data.success) {
          this.attrConfig = res.data.data

          //배당설정
          this.oddevenConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_ODDEVEN)
          })

          this.leftrightConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LEFT_RIGHT)
          })

          this.lineConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_LINE_COUNT)
          })
          this.crossConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_SUREMAN_SADALI_1M_CROSS)
          })
        }
      })
    }
  },
  created() {
    this.initKindConfig(this.kind)
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_SUREMAN);
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
  },
}
</script>

<style scoped>
.suremanlink {
  position: absolute;
  width: 1700px;
  height: 1650px;
  overflow: hidden;
  transform: scale(0.45);
  transform-origin: 0px 0px 0px;
}

.suremanlink iframe {
  width: 1700px;
  height: 1650px;
}

@media screen and (max-width: 820px) {
  .dmr2 {
    min-height: 410px !important;
  }

  .suremanlink {
    position: absolute;
    left: 150px;
    overflow: hidden;
    transform: scale(0.23);
    transform-origin: 0px 0px 0px;
  }
}

@media screen and (max-width: 768px) {
  .dmr2 {
    min-height: 410px !important;
  }

  .suremanlink {
    position: absolute;
    left: 150px;
    overflow: hidden;
    transform: scale(0.23);
    transform-origin: 0px 0px 0px;
  }
}

@media screen and (max-width: 540px) {
  .dmr2 {
    min-height: 410px !important;
  }

  .suremanlink {
    position: absolute;
    left: 10px;
    overflow: hidden;
    transform: scale(0.23);
    transform-origin: 0px 0px 0px;
  }
}

@media screen and (max-width: 414px) {
  .dmr2 {
    min-height: 410px !important;
  }

  .suremanlink {
    position: absolute;
    left: 10px;
    overflow: hidden;
    transform: scale(0.23);
    transform-origin: 0px 0px 0px;
  }
}

@media screen and (max-width: 390px) {
  .dmr2 {
    min-height: 400px !important;
  }

  .suremanlink {
    position: absolute;
    left: 10px;
    overflow: hidden;
    transform: scale(0.21);
    transform-origin: 0px 0px 0px;
  }
}

@media screen and (max-width: 375px) {
  .dmr2 {
    min-height: 400px !important;
  }

  .suremanlink {
    position: absolute;
    left: 5px;
    overflow: hidden;
    transform: scale(0.21);
    transform-origin: 0px 0px 0px;
  }
}

@media screen and (max-width: 360px) {
  .dmr2 {
    min-height: 380px !important;
  }

  .suremanlink {
    position: absolute;
    left: 3px;
    overflow: hidden;
    transform: scale(0.2);
    transform-origin: 0px 0px 0px;
  }
}
</style>